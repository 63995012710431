@use 'sass:map'
@import 'variables.scss'

.w-40
  width: 40%

.section
  width: 45%

@media screen and (min-width: map.get($breakpoints, 'xxl'))
  .section
    width: 48%

.w-35
  width: 35%

.h-44px
  height: 44px

.black-40
  color: $bbraun-gray-80

.snackbar
  max-width: 350px

.align-self-center
  align-self: center

.cx-image-container.cx-image-fit-cover
  .cx-image
    object-fit: contain !important

.required-icon
  padding-left: 10px
  position: relative
  &:before
    color: $bbraun-error
    font-size: $bbraun-font-size-big
    display: block
    content: "*"
    position: absolute
    width: 10px
    height: 10px
    left: 0
    top: -4px
