@import 'bbraun-theme'
@import 'base.main'
@import 'base.table'
@import 'base.mat-list'

.form-field-chip-list-no-background
  &.mat-form-field-appearance-outline
    .mat-form-field-outline
      display: none

    .mat-form-field-flex

      padding: 4px 0 !important

    .mat-form-field-infix
      width: 100%
      border-top-width: 8px
      padding-bottom: 2px !important

.mat-expansion-panel-header
  height: 64px !important

.ellipsis-2
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  word-break: break-all

.ellipsis
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
  line-height: 48px

.item-position-background
  background-color: $bbraun-background
  padding: $bbraun-spacing-2xs
  border-radius: $bbraun-border-radius

.product-class-field
  .mat-form-field-wrapper
    padding-bottom: 0px

.material-multi-select-component-label
  margin-bottom: -18px
  margin-top: 24px !important
