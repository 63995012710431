@import 'variables.scss'

.mat-paginator
  padding-top: 24px !important

.mat-paginator-container
  justify-content: center !important

.mat-paginator-range-label
  display: none

.mat-custom-page
  box-shadow: none !important
  margin-left: 6px !important
  margin-right: 6px !important
  line-height: 32px !important
  height: 32px !important
  width: 32px !important
  text-align: center !important
  &:hover
    background: rgba(51, 51, 51, 0.08) !important

.mat-paginator-navigation-next,
.mat-paginator-navigation-previous
  width: 32px !important
  height: 32px !important
  line-height: 32px !important
  &:hover
    background: rgba(51, 51, 51, 0.08) !important
  .mat-button-wrapper
    display: flex !important
    .mat-paginator-icon
      width: 32px !important

.mat-paginator-selected
  background-color: $bbraun-green-100
  color: $bbraun-space
  &:hover
    background: -100 !important

.mat-tooltip.large
  max-width: 300px !important

.red-cx-banner .banner
  background-color: rgba($bbraun-error, 0.08) !important

  .banner-icon
    color: $bbraun-error

.red-cx-banner-bordered .banner
  background-color: transparent !important
  font-size: $bbraun-font-size-medium !important
  border: 1px solid $bbraun-error
  color: $bbraun-error !important

  .banner-padding
    padding: $bbraun-spacing-sm 0 !important

  .banner-icon
    color: $bbraun-error
    margin: 0 $bbraun-spacing-xs 0 $bbraun-spacing-sm !important

  .pr-m
    padding-right: $bbraun-spacing-sm !important

// Mat button with icon on the right
.cx-button-with-icon
  .mat-icon.cx-icon-in-button.icon-on-right
    margin-left: 10px !important
    margin-top: -2px !important
    margin-right: -2px !important

// BBraun's border radius
.bg-white
  background-color: $bbraun-space

.bg-light-gray-40
  background-color: $bbraun-gray-20

.rounded
  border-radius: $bbraun-border-radius

.mat-dialog-title
  word-break: break-all

.option-heart-beat cx-heart-beat img
  max-width: 275px !important

.border-bottom-light-gray-40
  border-bottom: 1px solid $bbraun-gray-20

.gap-small
  gap: $bbraun-s-gutter

.mat-dialog-container
  .mat-dialog-title, .mat-dialog-content
    word-break: break-word

  .mat-dialog-content p
    &:first-of-type
      margin-top: 0

    &:not(:first-of-type):last-of-type
      margin-bottom: 0

.mdc-list-item__content span
  word-break: break-word

h1, h2
  .mat-icon
    flex-shrink: 0

    & + span
      word-break: break-word

.mat-mdc-dialog-container
  .mat-mdc-dialog-content.mdc-dialog__content
    overflow-y: hidden
